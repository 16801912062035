import React from 'react';
import { useTranslation } from 'react-i18next';

import allNodeSelectListQuery from '@modules/apply_form/default-query';

import Hero from '@shared/hero/hero';
import { Graphql } from '@models/graphql';
import JobsAreaAccordion from '@shared/accordion/jobs-area';

import UseForm from './utils/use-form';
import TalentFormApply from './forms/talent-apply-form';

type CreateApplyFormProps = {
  language: string
};
export default function CreateApplyForm({ language }: CreateApplyFormProps) {
  const { areas, jobs } = allNodeSelectListQuery(language);
  const { t } = useTranslation();

  const heroData = {
    title: t('Talent Form Application'),
    margin: 'mb-5',
    relationships: {
      image: {
        name: 'background_qualityForm.png',
      },
    },
  } as Graphql;

  return (
    <>
      <UseForm showLoading={false}>
        <Hero node={heroData} />
        <div className="container">
          <div className="row">
            <div className="col-md-4 pr-md-5">
              <JobsAreaAccordion
                areas={areas}
                jobs={jobs}
                title={t('Active needed jobs')}
                subtitle={t('We are always seeking qualified people to be part of our team, our requests always change but if you are good please apply to any role, we have listed in here are actual available jobs needs')}
              />
            </div>
            <div className="col-md-8">
              <TalentFormApply language={language} />
            </div>
          </div>
        </div>
      </UseForm>
    </>
  );
}
