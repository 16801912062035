export function unique(array: object[]): object[] {
  return array?.filter((element: object, index: number, _array: object[]) => {
    if (typeof element === 'string') {
      return _array.indexOf(element) === index;
    }

    return _array
      .findIndex(
        (_element, _index) => JSON.stringify(_element) === JSON.stringify(element)
      ) === index;
  }) || [];
}

export function flat(_array: object[]) {
  // TODO Complete this
}
